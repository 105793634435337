<script>
import LaunchOverlay from "@/components/LaunchOverlay.vue";

export default {
  name: "HomeBanner",
  components: {LaunchOverlay},
  data() {
    return {
      slide: 0,
      sliding: null,
      banner: undefined
    }
  },
  computed:{
    images(){
      if(!this.banner)return []
      return (this.banner.infos ?? []).sort((a,b)=>a.orderNum-b.orderNum).map(e=>e.images).filter(e=>e.length>0)
    }
  },
  mounted() {
    this.getInfoByCode("banner").then(banner=>this.banner=banner)
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<template>
  <div class="mb-lg-5 pb-lg-5">
<!--    <launch-overlay-->
<!--        :mediaSource="(images[0].endsWith('mp4') ? images[0] : images[slide])|imageUrl"-->
<!--        :isVideo="(images[0].endsWith('mp4') ? images[0] : images[slide]).endsWith('mp4')"-->
<!--        :scrollThreshold="1"-->
<!--        show-once-->
<!--        v-if="images.length > 0"-->
<!--    >-->
<!--    </launch-overlay>-->
    <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        fade
        indicators
        background="#ababab"
        img-width="1024"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(imgUrl, i) in images" :key="i">
        <template v-slot:img>
          <video v-if="imgUrl.endsWith('mp4')" class="d-block w-100 item" :src="imgUrl|imageUrl" autoplay loop muted playsinline>
            Your browser does not support the video tag.
          </video>
          <img v-else class="d-block w-100 item" :src="imgUrl|imageUrl" :alt="`Slide ${i + 1}`">
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>

</template>

<style scoped lang="scss">
//.carousel{
//  top: 56px;
//}
::v-deep .carousel-indicators li{
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-clip: unset;
  border-top: unset;
  border-bottom: unset;
}
.item{
  aspect-ratio: 1920 / 1080;
  object-fit:cover;
}
//@media (min-width: 992px) {
//  .carousel{
//    top: -86px;
//  }
//}
</style>
