<script>
import BackgroundView from '@/components/BackgroundView.vue';
import SearchOverlay from '@/components/SearchOverlay.vue';
import RecursiveMenu from '@/components/RecursiveMenu.vue';
import * as $ from 'jquery';
export default {
  name: 'MyNav',
  components: { SearchOverlay, BackgroundView, RecursiveMenu },
  inject: ['heights'],
  methods: {
    // setHomePage() {
    //   console.log('homepage');
    // },
    // setFavarite() {
    //   console.log('setFavarite');
    // },
    setLang(l) {
      this.$i18n.locale = l.dictValue;
      window.sessionStorage.setItem("lang", l.dictValue)
    },
    hover(v, b) {
      v ? b.srcElement.__vue__.show() : b.srcElement.__vue__.hide();
    },
    handleScroll() {
      // 检查滚动位置
      if ($(window).scrollTop() > 400) {
        // 假设阈值为100px
        // 隐藏元素
        $('.bar-bg').fadeOut(400);
      } else {
        // 显示元素
        $('.bar-bg').fadeIn(400);
      }
    },
    clickMenu(item) {
      if (this.$refs.navCollapse.show) {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
      }
      if (item.url) {
        if (item.url == '/#/') {
          if (this.$route.path == '/') {
            return;
          } else {
            this.$router.push('/');
          }
        } else if (item.url.startsWith('http')) {
          window.open(item.url);
        } else {
          console.log(item);
          this.$router
            .push({
              path: item.url,
              query: {
                code: item.menuCode,
                id: item.id,
              },
            })
            .catch(() => {
              console.log('err');
            });
        }
        //外链
      } else {
        if (1 == item.menuType) {
          console.log(item); //列表类型的页面
          if (`/list?id=${item.id}` == this.$route.fullPath) {
            return;
          }
          this.$router
            .push({
              path: 'list',
              query: {
                id: item.id,
              },
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          //详情类型的页面
          if (`/gaikuang?id=${item.id}` == this.$route.fullPath) {
            return;
          }
          this.$router
            .push({
              path: 'gaikuang',
              query: {
                id: item.id,
              },
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
  },
  mounted() {
    $(window).on('scroll', this.handleScroll);
    this.getMenusByCode('caidan').then((menus) => {
      console.log(menus)
      if (menus && menus.children) {
        menus.children.unshift({
          id: -1,
          label: this.$t('home'),
          url: '/#/',
        });
        this.menus = menus;
      }
    });
    this.getMenusByCode('logo').then((logo) => (this.logo = logo));
    this.$axios.get('/website/menu/getAllLanguages').then((res) => {
      if (res.data.code == 200) {
        this.language = res.data.data
      }
    })
    new ResizeObserver(entries => {
      for (let entry of entries) {
        if (window.innerWidth >= 992) {
          this.heights.navHeight = entry.target.clientHeight + "px";
        }
      }
    }).observe(this.$refs.resizeElement);
    new ResizeObserver(entries => {
      for (let entry of entries) {
        if (window.innerWidth < 992) {
          this.heights.navHeight = entry.target.clientHeight + "px";
        }
      }
    }).observe(this.$refs.resizeElementParent);
  },
  beforeDestroy() {
    // 清理事件监听器
    $(window).off('scroll', this.handleScroll);
  },
  data() {
    return {
      showSearch: false,
      menus: { children: [] },
      logo: {},
      language: undefined
    };
  },
  computed: {
    logoUrl() {
      if (this.logo && (this.logo.images || '').length > 0) {
        // eslint-disable-next-line no-undef
        return (typeof apiUrl != 'undefined' ? apiUrl : 'http://192.168.1.18:80/dev-api') + this.logo.images;
      }
      return '/assets/logo.png';
    },
  },
};
</script>

<template>
  <div class="fixed-top" ref="resizeElementParent">
    <div class="bar-bg z-index-2 position-relative p-2 text-small text-primary" ref="resizeElement">
      <div class="container-lg d-flex justify-content-center align-items-center justify-content-lg-between">
        <div class="d-flex flex-column justify-content-between flex-lg-row flex-grow-1">
          <div>{{ $t('intro') }}</div>
          <div class="d-flex flex-wrap align-items-center">
            <template v-for="(item, i) in language">
              <a :key="item.id" class="c-pointer" @click="setLang(item)" style="padding: 0px 3px;cursor: pointer;">{{
                item.dictValue }}</a><span :key="i" v-if="i < language.length - 1"> | </span>
            </template>
            &nbsp;<img src="../assets/weather.png" />&nbsp;
            <iframe width="210" scrolling="no" height="15" frameborder="0" allowtransparency="true"
              src="https://i.tianqi.com?c=code&id=71&color=%23005341&icon=1&py=laochengqu&site=12"></iframe>
          </div>
        </div>
      </div>
    </div>
    <background-view type="nav-bg-opacity" background-color="#000">
      <b-navbar toggleable="lg" type="dark" ref="naviBar" class="container-lg" style="padding:0px 15px;" >
        <b-navbar-brand href="#/" class="flex-shrink-0 mr-auto logo">
          <img alt="logo" :src="logoUrl" />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse ref="navCollapse" id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto flex-lg-wrap">
            <div v-for="item in menus.children" :key="item.id">
              <b-nav-item-dropdown v-if="item.children && item.children.length > 0" v-b-hover="hover"
                :text="item.label">
                <b-dropdown-item @click="clickMenu(sMenu, true)" v-for="sMenu in item.children" :key="sMenu.id">{{
                  sMenu.label }}</b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item @click="clickMenu(item, true)" v-else>{{ item.label }}</b-nav-item>
            </div>
            <!--            <recursive-menu :items="menus.children" @menu-click="clickMenu" />-->
          </b-navbar-nav>
          <!--          <search-overlay ref="searchOverlay">-->
          <!--            <b-button size="sm" class="my-2 my-sm-0" type="submit" @click="$refs.searchOverlay.toggleOverlay()">-->
          <!--              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">-->
          <!--                <path-->
          <!--                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"-->
          <!--                />-->
          <!--              </svg>-->
          <!--            </b-button>-->
          <!--          </search-overlay>-->
        </b-collapse>
      </b-navbar>
    </background-view>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/app.scss";

/* 覆盖默认的文本颜色 */
::v-deep.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  /* 深色文本颜色 */
}

/* 覆盖悬停状态的文本颜色 */
::v-deep.navbar-dark .navbar-nav .nav-link:hover,
::v-deep.navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
  /* 深色文本颜色 */
}

/* 覆盖激活状态的文本颜色 */
::v-deep.navbar-dark .navbar-nav .nav-link:active {
  color: #fff;
  /* 深色文本颜色 */
}

::v-deep {
  .navbar-dark {
    .navbar-nav {
      .nav-menu {
        .menu-item {
          .label {
            color: #fff;

            &:hover {
              text-decoration: none;
            }
          }

          color: #fff;

          &:hover {
            background-color: unset;
          }
        }

        &.horizontal {
          .sub-menu {
            &.open {
              li {
                &:hover {
                  background-color: #f4f4f4;
                }
              }
            }
          }
        }
      }

      .sub-menu {
        .menu-item {
          .label {
            color: #000;

            &:hover {
              text-decoration: none;
            }
          }

          color: black;
        }
      }
    }
  }
}

label {
  margin-bottom: unset;
}

@media (max-width: 992px) {
  ::v-deep {
    .logo img {
      width: 130px;
    }
  }
}

@media (min-width: 992px) {
  ::v-deep {
    .logo img {
      height: 28px;
    }
  }
}
</style>
