<script>
export default {
  name: "TitleLeftView",
  props: {
    showLeftBg: Boolean,
    showRightBg: Boolean,
    showMore: {
      type: Boolean,
      default: true
    },
    direction: String,//row row1 row2
    top: String
  },
  methods: {
    gotoMore() {
      this.$emit('more')
    }
  }
}
</script>

<template>
  <div :class="['position-relative','d-lg-flex','flex-lg-grow-1',...(direction ? [direction] : []),$t('lang')]">
    <div class="rect" :style="top ? {top} : {}" v-if="showRightBg"></div>
    <div class="left-rect" :style="top ? {top} : {}" v-if="showLeftBg"></div>
    <div class="d-flex flex-grow-1 align-items-lg-center outer">
      <slot name="section2"></slot>
      <div class="title-view d-flex flex-row align-items-center mt-4 justify-content-lg-center flex-grow-1 justify-content-between position-relative">
        <!--      标题-->
        <div class="font-weight-bold text-dark h3 mb-auto title"><slot></slot></div>
        <!--      更多-->
        <div @click="gotoMore" v-if="showMore" class="pt-1 pt-lg-4 pb-1 pb-lg-4 pl-4 pl-lg-1 pr-4 pr-lg-1 mt-lg-4 c-pointer more d-flex flex-row align-items-center">{{$t('more')}}
          <svg xmlns="http://www.w3.org/2000/svg" width="16"  height="16" fill="currentColor" class="flip-image flip-image-lg bi bi-chevron-double-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
      </div>
      <slot name="section1"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../assets/scss/app.scss";
.title-view {
  writing-mode: unset; /* 表示文字从上到下排列 */
  display: inline-block; /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
  .title{
    overflow: hidden;
    max-height: 5em;
  }
}
.zh .title-view{
  letter-spacing: 0.5em;
  .title{
    overflow: initial;
    max-height: unset;
  }
}
.more { border: 1px solid $gray-600; border-radius: 5px; padding: 0 5px; }
.flip-image {
  transform: rotate(-90deg);
}
.row1 .outer{
  flex-direction: column-reverse !important;
}
.row2 .outer{
  flex-direction: column !important;
}
@media (min-width: 992px){
  .title-view {
    writing-mode: vertical-rl; /* 表示文字从上到下排列 */
    display: inline-block; /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
  }
  .flip-image-lg {
    transform: unset !important;
  }
  .row{
    .title-view{
      flex-direction: column !important;
      .more{
        margin-top: 0 !important;
        margin-right: 1em;
      }
    }
  }
  .row1 .outer{
    flex-direction: row !important;
  }
  .row2 .outer{
    flex-direction: row !important;
  }
}
.rect{
  position: absolute;
  top: -10%;
  bottom: 10%;
  background-color: $paleBlue;
  z-index: -5;


  left: 0;
  right: 0;
  aspect-ratio: unset;
}
.left-rect{
  position: absolute;
  top: -10%;
  bottom: -30%;
  background-color: $paleBlue;
  z-index: -5;


  left: 0;
  right: 0;
  aspect-ratio: unset;
}
@media screen and (max-width: 768px) {
  .rect,.left-rect {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .rect,.left-rect {
    top: 0;
    height: 5em;
  }
}
@media (min-width: 992px){
  .col-lg-2 .left-rect{
    top: -10%;
    bottom: -10%;
    left: -20%;
  }
  .row1 .outer{
    margin-bottom: 50%;
  }
  .rect{
    left: auto;
    right: 0;
    aspect-ratio: 1;
  }
  .left-rect{
    right: auto;
    left: 0;
    aspect-ratio: 1;
  }
  //.row1 .rect{
  //  left: unset;
  //  right: 0;
  //}
  //.row .rect{
  //  left: unset;
  //  right: 0;
  //}
}
</style>
