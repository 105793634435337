<template>
  <div id="app">
    <my-nav></my-nav>
    <background-view background-color="#f4f4ed">
      <div class="placeholder" :style="{height}"></div>
      <router-view  :key="`${$route.path}${$route.hash}`" />
    </background-view>
    <my-footer></my-footer>
    <img alt="Vue logo" src="/assets/bg.png" class="floatImage w-100">
  </div>
</template>

<script>
import MyNav from "@/components/MyNav.vue";
import MyFooter from "@/components/MyFooter.vue";
import BackgroundView from "@/components/BackgroundView.vue";

export default {
  name: 'App',
  components: {
    BackgroundView,
    MyFooter,
    MyNav,
  },
  data(){
    return {
      heights: {
        navHeight: "1.88em"
      }
    }
  },
  provide() {
    return {
      heights: this.heights
    };
  },
  watch: {
    "$i18n.locale": function (lang,oldVal){
      if(lang != oldVal){
        window.location.reload()
      }
    }
  },
  computed:{
    height:{
      get(){
        return this.heights.navHeight;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.floatImage{

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.7;
  z-index: -22;
}
</style>
