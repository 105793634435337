<script>
import TitleLeftView from "@/components/TitleLeftView.vue";
import * as $ from 'jquery'
export default {
  name: "HomeNewsIntro",
  components: {TitleLeftView},
  data() {
    return {
      imgInfo: undefined,
      slide: null,
      pageSize: 3,
      showToolbar: false,
    }
  },
  computed:{
    items(){
      if(!this.imgInfo)return []
      return (this.imgInfo.infos ?? []).sort((a,b)=>a.orderNum-b.orderNum)
    },
    slideTotalPages(){
      return Math.ceil(this.items.length / this.pageSize)
    }
  },
  mounted() {
    this.getInfoByCode("jingqudongtai").then(imgInfo=>this.imgInfo=imgInfo)
    this.updatePageSize()
    $(window).on('resize', ()=> {
      this.updatePageSize()
    });
  },
  watch:{
    pageSize:{
      handler(){
        this.slide = 0;
      }
    }
  },
  methods:{
    openLink(item){
      if((item.url || "").length > 0)
        window.open(item.url)
      else this.$router.push({
        name: 'detail',
        query: { id: item.id },
      });
    },
    gotoMore(){
      this.$router.push({ path: 'jingqudongtai', query: { code: 'jingqudongtai' }})
    },
    updatePageSize(){
      // 获取当前窗口的宽度和高度
      const width = $(window).width();
      const md = 768;
      const sm = 576;
      const lg = 992;
      // const xl = 1200;
      this.showToolbar = width >= lg;
      if(width >= md){
        this.pageSize = 3
      }else if(width >= sm){
        this.pageSize = 2
      }else{
        this.pageSize = 1
      }
    }
  }
}
</script>

<template>

  <!--------------------------------------
  HomeNewsIntro
  --------------------------------------->
  <section class="d-flex flex-column-reverse flex-lg-row-reverse pt-lg-5 pb-1 w-100">
    <b-carousel
        v-model="slide"
        :interval="3000"
        class="col-lg-9"
    >
      <b-carousel-slide v-for="pageIndex in Math.ceil(items.length / pageSize)" :key="pageIndex">
        <template v-slot:img>
          <div class="w-100 d-flex">
            <div class="col-md-4 col-sm-6 col-12" v-for="item in items.slice((pageIndex-1)*pageSize,pageIndex*pageSize)" :key="item.id">
              <div class="card border-0 c-pointer" @click="openLink(item)">
                <b-img-lazy class="card-img-top" :src="item.images | imageUrl" alt="Card image cap" style="aspect-ratio: 400 / 237;"></b-img-lazy>
                <div class="card-body">
                  <h5 class="card-title ellipsis">{{ item.title }}</h5>
                  <p class="card-text text-muted ellipsis-2" style="min-height: 48px">
                    {{ item.remark }}
                  </p>
                  <div class="card-text text-muted pb-1">{{ $dayjs(item.createTime).format('YYYY.MM.DD') }}</div>
                  <button type="button" class="btn btn-light w-100">{{ $t('viewDetails') }}</button>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <title-left-view class="col-lg-3 flex-shrink-0 leftView" top="-25%" show-left-bg direction="row2" @more="gotoMore">
      {{ imgInfo?.menuName }}
      <template v-if="showToolbar" #section1>
        <div class="d-flex flex-column align-items-lg-end col-lg-5" v-if="slideTotalPages > 1">
          <div class="d-flex flex-column align-items-center justify-content-between w-100 mt-5 text-black-50">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle c-pointer" viewBox="0 0 16 16" @click="slide = (slide == 0 ? slideTotalPages - 1 : slide-1)">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle c-pointer" viewBox="0 0 16 16" @click="slide = (slide == slideTotalPages - 1 ? 0 : slide+1)">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </div>
          <div class="d-flex align-items-baseline" style="margin-top: 8em"><h1>0{{ slide+1 }}</h1> <h6 class=" text-black-50">/0{{ slideTotalPages }}</h6></div>
          <b-progress height="2px" :value="(slide+1)/slideTotalPages * 100" class="mt-4 w-100"></b-progress>
        </div>
      </template>
    </title-left-view>
  </section>
</template>

<style scoped lang="scss">
section{
  background: url("../assets/map_bg.png") no-repeat bottom right;
}
.leftView{
  margin-bottom: 1em
}
@media (min-width: 992px) {
  .leftView{
    margin-bottom: 5em
  }
}
</style>
