<script>
import TitleView from "@/components/TitleView.vue";
import FlipAnimationView from "@/components/FlipAnimationView.vue";
import BackgroundView from "@/components/BackgroundView.vue";
import HomeImageIntroCell from "@/views/components/HomeImageIntroCell.vue";
import TitleLeftView from "@/components/TitleLeftView.vue";
import ZoomAnimationView from "@/components/ZoomAnimationView.vue";
import * as $ from "jquery";

export default {
  name: "HomeOpenIntro",
  components: {
    ZoomAnimationView, TitleLeftView, HomeImageIntroCell, BackgroundView, FlipAnimationView, TitleView
  },
  data() {
    return {
      imgInfo: undefined,
      slide: null,
      showToolbar: false,
    }
  },
  computed: {
    images() {
      if (!this.imgInfo) return []
      return (this.imgInfo.infos ?? []).sort((a, b) => a.orderNum - b.orderNum)
    },
    realImages() {
      if (this.images.length > 0) {
        return (this.images[0].images || "").split(',').filter(e => e.length > 0)
      }
      return []
    }
  },
  mounted() {
    this.getInfoByCode("kaifangshijian").then(imgInfo => this.imgInfo = imgInfo)

    this.updatePageSize()
    $(window).on('resize', () => {
      this.updatePageSize()
    });
  },
  methods: {
    openLink(item) {
      if ((item.url || "").length > 0)
        window.open(item.url)
    },
    updatePageSize() {
      // 获取当前窗口的宽度和高度
      const width = $(window).width();
      // const md = 768;
      // const sm = 576;
      const lg = 992;
      // const xl = 1200;
      this.showToolbar = width >= lg;
    }
  }
}
</script>

<template>

  <!--------------------------------------
  CARDS
  --------------------------------------->
  <section class="d-flex flex-column-reverse flex-lg-row pb-lg-5 w-100">
    <div class="position-relative col-lg-9 d-flex flex-lg-row flex-column justify-content-lg-end"
      v-if="images.length > 0">
      <div class="rect z-index-2" style="padding: 36px;" v-if="(images[0].content || '').length > 0">
        <div v-html="images[0].content" class="content" style="margin: 0px;"></div>
      </div>
      <b-carousel class="shadow col-lg-10 p-1" v-model="slide" :interval="3000" fade v-if="realImages.length > 0">
        <b-carousel-slide v-for="(imgUrl, i) in realImages" :key="i">
          <template v-slot:img>
            <img class="w-100" :src="imgUrl | imageUrl" :alt="`Slide ${i + 1}`">
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <title-left-view class="col-lg-3 titleView" show-right-bg direction="row1" :show-more="false">
      {{ imgInfo?.menuName }}
      <template v-if="showToolbar" #section2>
        <div class="d-flex flex-column align-items-lg-end col-lg-5" v-if="realImages.length > 1">
          <div class="d-flex align-items-baseline" style="margin-top: 8em">
            <h1>0{{ slide + 1 }}</h1>
            <h6 class=" text-black-50">/0{{ realImages.length }}</h6>
          </div>
          <b-progress height="2px" :value="(slide + 1) / realImages.length * 100" class="mt-4 w-100"></b-progress>
          <div class="d-flex align-items-center justify-content-between w-100 mt-5 text-black-50">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-arrow-left-circle c-pointer" viewBox="0 0 16 16"
              @click="slide = (slide == 0 ? realImages.length - 1 : slide - 1)">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
              class="bi bi-arrow-right-circle c-pointer" viewBox="0 0 16 16"
              @click="slide = (slide == realImages.length - 1 ? 0 : slide + 1)">
              <path fill-rule="evenodd"
                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
            </svg>
          </div>
        </div>
      </template>
    </title-left-view>
  </section>
</template>

<style scoped lang="scss">
@import "../assets/scss/app.scss";

.col1-md,
.col2-md,
.col3-md {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.rect {
  position: relative;
  background-color: #adcfc8;
}

.title-view {
  writing-mode: unset;
  /* 表示文字从上到下排列 */
  display: inline-block;
  /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
}

//.titleView{
//  margin-bottom: 1em;
//}
@media (min-width: 992px) {
  section {
    margin-top: 12em !important;
  }

  .rect {
    top: -20%;
    left: 10%;
    position: absolute;
    // aspect-ratio: 492 / 398;
    background-color: #adcfc8;
    //max-height: 85%;
    // min-height: 430px;
  }

  .title-view {
    writing-mode: vertical-rl;
    /* 表示文字从上到下排列 */
    display: inline-block;
    /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
  }

  .titleView {
    //margin-bottom: 8em;
  }

  .col1-md {
    -ms-flex: 0 0 percentage(360/1304) !important;
    flex: 0 0 percentage(360/1304) !important;
    max-width: percentage(360/1304) !important;
  }

  .col2-md {
    -ms-flex: 0 0 percentage(479/1304) !important;
    flex: 0 0 percentage(479/1304) !important;
    max-width: percentage(479/1304) !important;
  }

  .col3-md {
    -ms-flex: 0 0 percentage(430/1304) !important;
    flex: 0 0 percentage(430/1304) !important;
    max-width: percentage(430/1304) !important;
  }

  .content {
    overflow: auto;
    height: auto;
    max-width: 492px; 
  }

  .divider {
    height: 2px;
    width: 1em;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $white;
    opacity: 0.5;
    height: 50px;
    outline-offset: -2px;
    -webkit-border-radius: 4px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #fff0;
    -webkit-border-radius: 0;
  }
}
</style>
