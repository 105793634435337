<script>
import TitleView from "@/components/TitleView.vue";
import FlipAnimationView from "@/components/FlipAnimationView.vue";
import BackgroundView from "@/components/BackgroundView.vue";
import HomeImageIntroCell from "@/views/components/HomeImageIntroCell.vue";
import TitleLeftView from "@/components/TitleLeftView.vue";
import ZoomAnimationView from "@/components/ZoomAnimationView.vue";

export default {
  name: "HomeMapIntro",
  components: {ZoomAnimationView, TitleLeftView, HomeImageIntroCell, BackgroundView, FlipAnimationView, TitleView},
  data() {
    return {
      imgInfo: undefined
    }
  },
  computed:{
    // images(){
    //   if(!this.imgInfo)return []
    //   return (this.imgInfo.infos ?? []).sort((a,b)=>a.orderNum-b.orderNum)
    // }
    address:{
      get(){
        if((this.imgInfo?.infos || []).length > 0){
          return this.imgInfo?.infos[0]
        }
        return undefined
      }
    }
  },
  mounted() {
    this.getInfoByCode("jingquditu").then(imgInfo=>this.imgInfo=imgInfo)
  },
  methods:{
    openLink(item){
      if((item.url || "").length > 0)
        window.open(item.url)
    },
    gotoMore(){
      window.open(this.imgInfo?.url || "https://www.amap.com/place/B0FFHUC3GQ", "_blank")
    },
  }
}
</script>

<template>

  <!--------------------------------------
  CARDS
  --------------------------------------->
  <section class="d-flex flex-column flex-lg-row pt-lg-5 pb-4 w-100">
    <title-left-view class="col-lg-2" show-left-bg @more="gotoMore">{{ imgInfo?.menuName }}</title-left-view>
    <div class="d-flex flex-wrap mt-5 flex-grow-1 shadow" >
<!--      <zoom-animation-view class="flex-grow-1">-->
        <img v-if="this.imgInfo && (this.imgInfo.images || '').length > 0" class="w-100" :src="this.imgInfo.images | imageUrl" alt="" style="width: 100%" />
<!--      </zoom-animation-view>-->
    </div>
    <div class="title-view ml-lg-4 d-lg-flex flex-lg-column-reverse position-relative col-lg-2 rect" >
      <div class="text-primary d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 1024 1024">
          <path fill="currentColor" d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416M512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544"></path>
          <path fill="currentColor" d="M512 448a64 64 0 1 0 0-128 64 64 0 0 0 0 128m0 64a128 128 0 1 1 0-256 128 128 0 0 1 0 256m345.6 192L960 960H672v-64H352v64H64l102.4-256zm-68.928 0H235.328l-76.8 192h706.944z"></path>
        </svg>
        &nbsp;{{ address?.title }}：</div>
      <div :class="[$t('lang'),'address']">{{ address?.remark }}</div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import "../assets/scss/app.scss";
.col1-md,.col2-md,.col3-md {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
section{
  //position: absolute;
  //top: -25%;
  //height: 500px;
  //left: -100px;
  //right: -990px;
  //width: 30rem;
  ////background-color: $paleBlue;
  //z-index: -5;
  background: url("../assets/map_bg.png") no-repeat bottom right;
}
.rect{
  z-index: -5;
}
.title-view {
  writing-mode: unset; /* 表示文字从上到下排列 */
  display: inline-block; /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
}
.address{
  overflow: hidden;
  max-height: 25em;
}
.address.zh{
  overflow: initial;
  max-height: unset;
}
@media (min-width: 992px) {
  .title-view {
    writing-mode: vertical-rl; /* 表示文字从上到下排列 */
    display: inline-block; /* 将按钮设置为内联块，以便它可以按照正常的文本流垂直排列 */
  }
  .col1-md {
    -ms-flex: 0 0 percentage(360/1304) !important;
    flex: 0 0 percentage(360/1304) !important;
    max-width: percentage(360/1304) !important;
  }
  .col2-md {
    -ms-flex: 0 0 percentage(479/1304) !important;
    flex: 0 0 percentage(479/1304) !important;
    max-width: percentage(479/1304) !important;
  }
  .col3-md {
    -ms-flex: 0 0 percentage(430/1304) !important;
    flex: 0 0 percentage(430/1304) !important;
    max-width: percentage(430/1304) !important;
  }
}
@media (max-width: 992px) {
  .rect{
    margin-top: 1em;
  }
}
</style>
